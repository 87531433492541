import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Nav, InputGroup, Form, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link, useParams } from 'react-router-dom';

import './Posts.css'; // Ensure you have the necessary CSS



//  get category to come thru and make a basic 'bag' that appears for the blog post 

const BlogWebpage = () => {
  const { postSlug } = useParams();

  const [blogData, setBlogData] = useState([]);
  const [post, setPost] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState(blogData);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reveal, setReveal] = useState(false);

  useEffect(() => {
    fetchBlogData();
  }, []);


  useEffect(() => {
    // Filtering blogData based on searchQuery
    const results = blogData.filter(blog =>
      blog.attributes.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBlogs(results);
  }, [searchQuery, blogData]);


  // this is currently for sidebar/searching only
  const fetchBlogData = async () => {
    try {
      const response = await fetch('https://strapi-production-f4a8.up.railway.app/api/blogs?populate=image,%20categories', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blogs = await response.json();
      setBlogData(blogs.data);

    } catch (err) {
      console.error('Fetch error:', err);
      setError('Failed to load blog post');
    } finally {
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1000); // 2-second delay
    }
  };




  useEffect(() => {
    const fetchPost = async (slug) => {
      try {
        const response = await fetch(`https://strapi-production-f4a8.up.railway.app/api/blogs?filters[slug][$eq]=${slug}&populate=*`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setPost(data.data[0]?.attributes);
      } catch (err) {
        console.error('Fetch error:', err);
        setError('Failed to load post');
      } finally {
        setTimeout(() => {
          setLoading(false);
          setReveal(true);
        }, 750); // 2-second delay
      }
    };
  

    fetchPost(postSlug);
  }, [postSlug]);

  const fetchPostsByCategory = async (filterType, filterValue) => {
          // returning null fer now..

    return null;
    try {
     
        if (!filterValue) {
            return;
        }
        let url = null
        if (filterType === 'categories') {
            url = `https://strapi-production-f4a8.up.railway.app/api/blogs?filters[categories][text][$eq]=${filterValue}`;
        } else {
            //  else its likely filtering by slug
            url = `https://strapi-production-f4a8.up.railway.app/api/blogs?filters[${filterType}][$eq]=${filterValue}`;
        }

    const response = await fetch(url, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // console.log("Data from fetchPostsByCategory:", data);
      // setPost(data.data[0].attributes);
      return data;

      } catch (err) {
          console.error('Fetch error:', err);
          setError('Failed to load blog post');
        } finally {
          // setTimeout(() => {
          //   setLoading(false);
          // }, 2000); // 2-second delay
        }
  };



  if (loading) {
    return (
      <Container className="text-center py-5 mt-5">
        <div className="text-center mt-5">
          <Spinner animation="border" variant="dark" role="status" className="loading-spinner" />
          <p className="text-dark mt-3">Loading...</p>
        </div>
      </Container>
    );
  }

  if (error) {
    return <Container className="text-center py-5">{error}</Container>;
  }

  if (!post) {
    return <Container className="text-center py-5">No post found</Container>;
  }

  return (
    <>
      {/* Main content */}
      <Container className="my-5">
        <Row>
          {/* Blog post content */}
          <Col lg={8}>
            {post && (
              <article className={`bg-white pt-4 ${reveal ? 'fade-in' : ''}`}>
                <h1 className="mb-1">{post.title}</h1>
                
                {/* Only showing image if it exists at all */}
                {post.image?.data?.attributes.url && (
                  <img
                    src={post.image.data.attributes.url}
                    alt={post.title}
                    className="img-fluid mb-1 w-50"
                  />
                )}

                <p className="lead mb-5">{post.description}</p>
                  {post.categories?.data.map((category, index) => (
                    <span
                      key={index}
                      className="badge bg-secondary me-2 mb-2"
                      onClick={() => fetchPostsByCategory('categories', category.attributes.text)}
                      style={{ cursor: 'cross-hair' }}
                    >
                      {category.attributes.text}
                    </span>
                  ))}
                {/* map the categories for blog post */}

                <ReactMarkdown remarkPlugins={[remarkGfm]} className="blog-content mx-5">
                    
                  {post.content}
                </ReactMarkdown>
              </article>
            )}
          </Col>

          {/* Sidebar */}
          <Col lg={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Search</Card.Title>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Search posts..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <InputGroup.Text>
                  🔍
                  </InputGroup.Text>
                </InputGroup>
              </Card.Body>
            </Card>

            {/* SETTING UP RECENT BLOG POST SECTION */}
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Latest Articles</Card.Title>
                <Nav className="flex-column">
                {filteredBlogs.map((blog, index) => (
                  <Nav.Link
                    as={Link}
                    to={`/blog/${blog.attributes.slug}`}
                    key={index}
                    // onClick={() => setReveal(false)}
                  >
                    {blog.attributes.title}
              </Nav.Link>
            ))}
                </Nav>
              </Card.Body>
            </Card>



            <Card>
              <Card.Body>
                <Card.Title>About the Author</Card.Title>
                <div className="d-flex align-items-center mb-2">
                  <img
                    src='../gitago-192.jpg'
                    className="me-2"
                    style={{ width: '40px', height: '40px' }}
                  />
                  <span className="fw-bold">David Bell</span>
                </div>
                <Card.Text>
                  David is a freelance developer and esports producer.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogWebpage;