import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import DEFAULT_IMAGE from "../../assets/images/NO_IMAGE.webp";
import { getImage } from "../../utils/parseContent";


const CardGallery = ({ currentItems, openModal, handleImageError }) => {
    
    return (
    <>
        <Row xs={1} md={2} lg={3} className="g-4">
            {currentItems.map((item) => (
                <Col key={item.id} className="d-flex justify-content-center fade-in">
                    <Card className="bg-dark text-white" style={{ width: "100%", height: "100%" }} onClick={() => openModal(item)}>
                        <Card.Img
                            variant="top"
                            src={getImage.small(item.url) || DEFAULT_IMAGE}
                            alt={item.title || ""}
                            onError={handleImageError}
                            style={{ width: "100%", height: "150px", objectFit: "cover", backgroundColor: "#1d1d1d" }}
                        />
                        <Card.Body className="card-body">
                            <Card.Title className="card-title">{item.title || ""}</Card.Title>
                            <Card.Text className="card-text">{item.description}</Card.Text>
                        </Card.Body>

                        {item.button?.length > 0 && (
                            <Card.Footer className="card-footer py-3 gap-1">
                                {item.button.length > 0 && item.button.map((btn, index) => (
                                    <Card.Link
                                        key={index}
                                        href={btn.link}
                                        target="_blank"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // setShowModal(false);
                                        }}
                                    >
                                        <Button size="sm" variant="primary" className="card-button">
                                            {btn.title || "View"}
                                        </Button>
                                    </Card.Link>
                                ))}
                            </Card.Footer>
                        )}
                    </Card>
                </Col>
            ))}
        </Row>
    </>
    );
};

export default CardGallery;
