import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Image, FloatingLabel } from 'react-bootstrap';
import SocialMediaIcons from '../Socials/Socials';
import emailjs from '@emailjs/browser';
import './ContactForm.css';

const ContactForm = ({ formProps }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState(''); // State for success or error message
  const [fadeOut, setFadeOut] = useState(false); // State for fade-out animation
  const formRef = useRef(null);
  const emailFormRef = useRef(null); // New reference for the form element

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    emailjs
      .sendForm('service_80g2rb9', 'send-form', emailFormRef.current, {
        publicKey: 'o3A1pE4qRu2j1wCGG',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setFadeOut(true);
          setTimeout(() => setMessage('Email sent successfully!'), 1000); // Show success message after fade-out
        },
        (error) => {
          console.log('FAILED...', error.text);
          setFadeOut(true);
          setTimeout(() => setMessage(
            <>
               Sorry {formData.name}, something went wrong.
             
              <br/>
              <br/>
               Feel free to reach out via Discord or Direct Email.
              <hr/>
              <SocialMediaIcons exclude={['twitter', 'linkedin', 'github']} />
            </>
          ), 1000); // Show error message after fade-out
    },
      );
  };

  console.log("This is form props", formProps);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  return (
    <Container className="my-5">
      <Row className={`bg-light shadow rounded overflow-hidden ${isVisible ? 'slide-in' : 'hidden-content'}`} ref={formRef}>
        <Col md={4} className="bg-secondary text-white p-0 d-flex flex-column justify-content-between">
          <div>
            <Image 
              src={formProps.image.data.attributes.url}
              alt="Contact" 
              fluid 
              rounded 
              className="mb-2 mt-5 form-image" 
            />
            <h2 className="h4 mb-0">{formProps.heading}</h2>
            <p className="mb-0">{formProps.description}</p>
          </div>
          <div className="mt-4 py-2" style={{ backgroundColor: "#282c34" }}>
            <SocialMediaIcons />
          </div>
        </Col>
        <Col md={8} className="p-4 position-relative">
          {!message ? (
            <div className={`form-container ${fadeOut ? 'fade-out' : ''}`}>
              <h2 className="mb-4">{formProps.form.heading}</h2>
              <Form onSubmit={handleSubmit} ref={emailFormRef}>
                {formProps.form.input.map((inputField) => (
                  <Form.Group className="mb-3" controlId={`form${inputField.label}`} key={inputField.id}>
                    <FloatingLabel
                      controlId={`floating${inputField.label}`}
                      label={inputField.label}
                      className="mb-3"
                    >
                      <Form.Control
                        type={inputField.label === 'Email' ? 'email' : 'text'}
                        placeholder={inputField.placeholder}
                        name={inputField.label.toLowerCase()}
                        value={formData[inputField.label.toLowerCase()]}
                        onChange={handleChange}
                        required
                        as={inputField.label === 'Message' ? 'textarea' : 'input'}
                        rows={inputField.label === 'Message' ? 3 : undefined}
                        style={inputField.label === 'Message' ? { height: '100px' } : {}}
                      />
                    </FloatingLabel>
                  </Form.Group>
                ))}
                <Button type="submit" className="w-100">
                  {formProps.form.button.title}
                </Button>
              </Form>
            </div>
          ) : (
<div className="success-message fade-in">
  <div className="form-icon-container">
    <img 
      src={message === 'Email sent successfully!' ? 'images/icons/envelope.svg' : 'images/icons/error.svg'} 
      alt={message === 'Email sent successfully!' ? 'SUCCESS!' : 'Error!'} 
      className="form-icon" 
    />
  </div>
  <p className="m-0">{message}</p>
</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;