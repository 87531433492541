import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faDesktop, faCode } from '@fortawesome/free-solid-svg-icons';
import './Counter.css';

const Counter = () => {
  const [countingStarted, setCountingStarted] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const counterSection = document.getElementById('services');
      const rect = counterSection.getBoundingClientRect();
      const halfWindowHeight = window.innerHeight / 2;

      if (rect.top <= halfWindowHeight && rect.bottom >= halfWindowHeight && !countingStarted) {
        const totalEvents = document.getElementById('total-events');
        const totalHours = document.getElementById('total-hours');
        const totalProjects = document.getElementById('total-projects');

        countTo(168, 2000, totalEvents);
        countTo(850, 2000, totalHours);
        countTo(26, 2000, totalProjects);

        // Set opacity to 1 and reset the position
        counterSection.style.opacity = 1;
        counterSection.style.transform = 'translateY(0)';

        setCountingStarted(true);
      }
    }

    function countTo(target, duration, element) {
      let start = 0;
      const increment = target / (duration / 50);
      const interval = setInterval(() => {
        start += increment;
        if (start >= target) {
          start = target;
          clearInterval(interval);
        }
        element.textContent = Math.floor(start);
      }, 50);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [countingStarted]);

  return (
    <>
    {/* Counter Section */}
    <section id="counters" className="py-4 mt-5 mb-5 counter-section">
      <Container>
        <Row className="counter-container">
          <Col className="counter-item text-center">
            <a href="https://docs.google.com/spreadsheets/d/1FprE5YzOXuu6fT0DB61F4vg5a9khF5FmY9vmSrZ4Bwk/edit#gid=866587148" target="_blank" rel="noopener noreferrer" className="text-decoration-none d-block">
              <FontAwesomeIcon icon={faChartBar} className="icon mb-2" />
              <div className="counter-value" id="total-events">168</div>
              <div className="counter-text">Total Events</div>
            </a>
          </Col>
          <Col className="counter-item text-center">
            <a href="https://www.bit.ly/Gitago" target="_blank" rel="noopener noreferrer" className="text-decoration-none d-block">
              <FontAwesomeIcon icon={faDesktop} className="icon mb-2" />
              <div className="counter-value" id="total-hours">850</div>
              <div className="counter-text">Hours Produced</div>
            </a>
          </Col>
          <Col className="counter-item text-center">
            <a href="https://github.com/gitagogaming?tab=repositories&q=&type=public&language=&sort=stargazers" target="_blank" rel="noopener noreferrer" className="text-decoration-none d-block">
              <FontAwesomeIcon icon={faCode} className="icon mb-2" />
              <div className="counter-value" id="total-projects">26</div>
              <div className="counter-text">Projects</div>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  </>
  );
}

export default Counter;