import React, { useState } from 'react';
import { Card, ListGroup, Image } from 'react-bootstrap';
import loadIcon from '../iconLoader'; // Adjust the import path as needed


const ServiceCard = ({ icon, title, content, hoverContent }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    // setIsHovered(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      className="servicesText w-100"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card.Body>
        
        <Image src={icon} className="servicesIcon" style={{ width: '30px', height: '30px', display: 'block', margin: '0 auto', filter: 'invert(1)' }} />


        {/* <Image src={icon} className="servicesIcon "  /> */}
        <Card.Title className="mb-3">{title}</Card.Title>
        <ListGroup >
          {/* {isHovered ? hoverContent : content} */}
          {isHovered ? hoverContent : content}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;