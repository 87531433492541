import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './LogoBar.css';


const importAll = (r) => r.keys().map(r);
const logos = importAll(require.context('../../assets/images/production/events/organizers', false, /\.(png|jpe?g|svg|webp)$/));

const LogoBar = () => {
  return (
    <Container className="logo-bar-container">
      <Row>
        <Col>
          <h2 className="headline">Event Organizers I've Worked With</h2>
          <hr className="divider" />
        </Col>
      </Row>
      <Row className="logo-bar-wrapper">
        <Col>
          <div className="logo-bar">
            {logos.map((logo, index) => (
              <img key={index} src={logo} alt={`Logo ${index + 1}`} className="logo" />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoBar;