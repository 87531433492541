import React, { useState, useEffect } from "react";
import { Container, Button, ButtonGroup, Spinner} from "react-bootstrap";
import Counter from "../Counters/Counters";
import LogoBar from "../LogoBar/LogoBar";

// import GenerateCards from "./GenerateCards";
// import ProdSection from "./ProductionSection";
// import GraphicDesignSection from "../../components/Portfolio/GraphicDesignSection";
import { useQuery, gql } from '@apollo/client';
import ImageGallery from "../ImageGallery/ImageGallery";
import "./Portfolio.css";

const GET_PORTFOLIO_BUTTONS = gql`
  query getPortfolioButtons {
      portfolioSections {
        data {
          attributes {
            title
            portfolioType
          }
        }
      }
    }
`;


const GET_PORTFOLIO_SECTION = gql`
  query getPortfolioSection($title: String!) {
    portfolioSections(filters: { title: { eq: $title } }) {
      data {
        attributes {
          title
          portfolioType
          Card {
            id
            title
            description
            descriptionLong
            isFeatured
            Image {
              data {
                id
                attributes {
                  name
                  url
                  
                }
              }
            }
            Button {
              title
              link
              type
              isExternal
            }
          }
        }
      }
    }
  }
`;



const Portfolio = ({ activeSection, setActiveSection }) => {
  const [isVertical, setIsVertical] = useState(window.innerWidth <= 600);
  const [currentPortfolio, setDisplayPortfolio] = useState('Production');
  const [currentPortfolioType, setCurrentPortfolioType] = useState('');



  // useEffect(() => {
  //   // "Workaround" for preloading a profile section based on the URL hash
  //   const updateActiveSectionFromURL = () => {
  //     const hash = window.location.hash.replace('#', '');
  //     const section = hash || 'Production';
  //     if (section && section !== activeSection) {
  //       setActiveSection(section);
  //     }
  //   };

  //   // Update section on mount
  //   updateActiveSectionFromURL();

  //   // Listen for hash changes
  //   window.addEventListener('hashchange', updateActiveSectionFromURL);

  //   // Clean up event listeners on unmount
  //   return () => {
  //     window.removeEventListener('hashchange', updateActiveSectionFromURL);
  //   };
  // }, [activeSection, setActiveSection])


  // Fetching from Strapi CMS & Portfolio Content
  const { loading: loadingButtons, error: errorButtons, data: dataButtons } = useQuery(GET_PORTFOLIO_BUTTONS);
  const { loading: loadingSection, error: errorSection, data: dataSection } = useQuery(GET_PORTFOLIO_SECTION, {
    variables: { title: currentPortfolio }
  });



  useEffect(() => {
    if (dataButtons) {
      const portfolioTypes = dataButtons?.portfolioSections?.data.map(section => ({
        title: section.attributes.title,
        portfolioType: section.attributes.portfolioType
      })) || [];
      
      const fetchcurrentPortfolioType = portfolioTypes.filter(portfolio => portfolio.title === currentPortfolio);
      const portfolioType = fetchcurrentPortfolioType[0]?.portfolioType;
      setCurrentPortfolioType(portfolioType);
    }
  }, [dataButtons, currentPortfolio]);
  
  console.log("Portfolio Type:", currentPortfolioType);

  const portfolioCards = dataSection?.portfolioSections?.data.flatMap(section =>
    section.attributes.Card.flatMap(card =>
      card.Image?.data ? [{ 
        url: card.Image.data.attributes.url,
        name: card.Image.data.attributes.name,
        description: card.description,
        descriptionLong: card.descriptionLong,
        button: card?.Button ? card.Button : null,
        id: card.id,
        title: card.title,        
        }] : []
    )
  ) || [];




  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (activeSection !== 'home') {
        setDisplayPortfolio(activeSection);
    }
  }, [activeSection]);





  const handleButtonClick = (buttonId) => {
    if (buttonId !== 'home') {
        setActiveSection(buttonId );
    }
  };

  if (loadingSection || loadingButtons) return <div>
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>;
  if (errorSection || errorButtons) return <div>Error: {errorSection?.message || errorButtons?.message}</div>;


   const buttons = dataButtons.portfolioSections.data.map((button) => {
    return {
      id: button.attributes.title,
      title: button.attributes.title,
      url: button.attributes.url
    };
  });



  return (
    <Container className="centered-container mt-4" id={currentPortfolio}>



        <ButtonGroup vertical={isVertical} className="mb-1 button-group">
          {buttons.map((button) => (
            <Button
              key={button.id}
              id={`${button.title.toLowerCase().replace(/\s+/g, '-')}-button`}
              className={`p-2 portfolio-button ${currentPortfolio === button.title ? "selected" : ""}`}
              onClick={() => handleButtonClick(button.title)}
            >
              {button.title}
            </Button>
          ))}
        </ButtonGroup>

      {/*  fading in/out the content based on if images if populated yet.. to avoid the glitching */}
      <div className={portfolioCards.length > 0 ? "fade-in portfolio-content" : "fade-out portfolio-content"}>
        <ImageGallery images={portfolioCards} viewType={currentPortfolioType} />
        
        
        {/*  If production then we are adding in counter & logo... need to somehow get these includes in CMS as option fields for production stuff.. 
         The logos for example are used now for production and even organizers, it could be reused for developing and showing the tech stack for example
          and the counter could be used for total lines of code written, total projects, total who knows what...
        */}
        {currentPortfolio === "Production" && (
          <>
            <Counter />
            <LogoBar />
          </>
      )}
    </div>
    </Container>
  );
};

export default Portfolio;
