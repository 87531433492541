import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import ServiceCard from './ServiceCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';


const Services = ({ servicesProps }) => {


  const generateContent = (data) => (
    <>
      {data.map((card, index) => (
        <React.Fragment key={index}>
          <ListGroup.Item className="listItemStyle">{card.text}</ListGroup.Item>

        </React.Fragment>
      ))}
    </>
  );


  return (
    <Container>
      <h1 className="text-center"></h1>
      <div>
        <Row>
          {servicesProps.map((card, index) => (
            <React.Fragment key={index}>
              <Col lg={4} className="mb-4 d-flex align-items-stretch">
                <ServiceCard
                  icon={card.image.data?.attributes.url || `images/icons/question.svg`}
                  title={card.heading}
                  content={generateContent(card.descriptionNormal)}
                  hoverContent={generateContent(card.descriptionHover)}
                />
              </Col>
             </React.Fragment>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Services;