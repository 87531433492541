import React from 'react';

const TextWithLineBreaks = ({ text }) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};



//  Used to append a prefix to the file name for fetching different sizes of images from strapi/cloudinary
const appendToFileName = (url, prefix) => {
  return url.replace(/([^/]+)$/, `${prefix}$1`);
};

const getImage = {
  thumbnail: (url) => appendToFileName(url, "thumbnail_"),
  small: (url) => appendToFileName(url, "small_"),
  medium: (url) => appendToFileName(url, "medium_"),
  large: (url) => appendToFileName(url, "large_"),
};

export { TextWithLineBreaks, getImage };