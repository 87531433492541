import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import DEFAULT_IMAGE from "../../assets/images/NO_IMAGE.webp";
import { getImage } from "../../utils/parseContent";

const GalleryView = ({ images, handleShow, imagesPerRow = 3 }) => {
    // Determine column size based on the number of images per row
    const getColSize = () => {
        switch(imagesPerRow) {
            case 1: return 12;  // Full width if only 1 image per row
            case 2: return 6;   // 50% width per image
            case 3: return 4;   // 33.33% width per image
            case 4: return 3;   // 25% width per image
            case 6: return 2;   // 16.67% width per image
            default: return 3;  // Default to 4 images per row (25% width)
        }
    };

  return (
    <>
      <Row className="d-flex justify-content-center flex-wrap">
        {images.map((image, index) => (
          <Col key={index} xs={12} sm={5} md={getColSize()} className="mb-4">
            <Card onClick={() => handleShow(index)}>
              <Card.Img
                src={getImage.small(image.url) || DEFAULT_IMAGE}
                style={{ height: '200px', objectFit: 'cover' }}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GalleryView;
