import React, {useState, useEffect} from "react";
import NavbarSection from "./components/Navbar/Navbar";
import { Toast, Spinner} from "react-bootstrap";

import "./style/App.css";
import Footer from "./components/Footer/FooterComponent";
import ContactForm from "./components/Contact/ContactForm";

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import Blog from './pages/Blog/Blog'
import Post from './pages/Post/Post';



///   ROUTING FOR REACT_ROUTER FOR POSTS SIDE BAR NOT WORKING YET.. AMONGST OTHER FABULOUS THINGS





//  need to finish workng on setting up the blog page.. watch over the nextjs tutorial and check the example code can find.. 
// need to determine the relation for 'Blog Posts' to category just ot make sure we using it properly



//////// -- ISSUES FOUND BY REINIER -- ////////
// Check 4k and 1440p resolutions for layout
// ----
//  Services.jsx --- Does not seem to be an issue?
// on hover of services card it zooms in but when the zoom is done, the block is positioned differently, making it sort of snap into a different location, making everything move and that looks as incorrect behavior.
// ^^ maybe iwth 4k/2k?  not doing in 1080p - remove animation???? since its not clickable so who cares...
//----
// On my Galaxy Fold the text Driven by Passion is partly underneath the top bar.  Is this horizotonal mode?


// ----- Ideas  ------ //
// add embeded youtube videos to the site.. one for tutorial one for esports ?
// when mobile mode, make pagination buttons float and stay at bottom at all times?
// lets make the everything slide into view as well as fading as we have done
// test login and register stuff with a database


// ---- TO DO ---- //
// google sheet gigs can be pulled into page using api and
// https://www.devwares.com/docs/contrast/react/table/datatables/
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Column%20Filter&selectedStory=Select%20Filter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
// --------


/// GRAPHQL SEEMS SLOWER THAN RESTAPI WHEN ONLY FETCHING SOMETHING WITH MAYBE 10 ITEMS...  //
// it was approx 1s compared to like .200ms


// We could send to backend if we can protect the graphql with token? // 
const client = new ApolloClient({
  uri: "https://strapi-production-f4a8.up.railway.app/graphql",
  // uri: "http://localhost:1337/graphql",
  cache: new InMemoryCache()
});


function App() {
  const [heroData, setHeroData] = useState(null);
  const [servicesData, setServicesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showToast, setShowToast] = useState(false);


  const [contactFormData, setContactFormData] = useState(null);

  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    async function fetchStrapi() {
      try {
        const response = await fetch('https://strapi-production-f4a8.up.railway.app/api/landing-pages', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const projects = await response.json();
        const landingData = projects.data[0];
        const blockSections = landingData.attributes.blocks;

        blockSections.forEach(block => {
          switch (block.__component) {
            case 'blocks.hero':
              setHeroData({ title: block.heading, description: block.text, image: block.image, imageQuote: block.imageQuote, link: block.link });

              break;
            case 'blocks.row':
              setServicesData(block.card);
              break;
            case 'blocks.cta':
              setContactFormData(block);
              break;
            default:
              // console.log("Unknown block:", block);
              break;
          }
        }
        )
      } catch (error) {
        console.error('Fetch error(frontend, fetchData):', error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000); // 2-second delay
      }
    };
  

    fetchStrapi();
  }, []);


  if (isLoading) {
    return  <div className="bg-black vh-100 vw-100 d-flex justify-content-center align-items-center">
    <div className="text-center">
      <Spinner animation="border" variant="light" role="status" className="loading-spinner" />
      {/* <img src="hamster-loader.gif" alt="hamster" className="hamster-circle" /> */}
      <p className="text-light mt-3">Waking the hamster.. </p>
    </div>
  </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }



  return (

    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <div>
            <header> 
              <NavbarSection activeSection={activeSection} setActiveSection={setActiveSection} />
            </header>
            <main>
          <Routes>
          <Route path="/" element={<Home props={{ activeSection, setActiveSection, heroData, servicesData, isLoading }} />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postSlug" element={<Post />} />
          <Route path="/*" element={<Home props={{ activeSection, setActiveSection, heroData, servicesData, isLoading }} />} />
          </Routes>
        </main>
          </div>

        <div id="contact" className="pt-2 bg-dark">
          <ContactForm  formProps = {contactFormData}/>
          <Footer />
        </div>


        </Router>

       
      </div>
    </ApolloProvider>
  );
}

export default App;
