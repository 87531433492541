// // cacheUtils.js
// let cacheInstance = null;

// const getCacheInstance = async () => {
//   if (!cacheInstance) {
//     cacheInstance = await caches.open('image-cache');
//   }
//   return cacheInstance;
// };

// export const cacheImage = async (url) => {
//   try {
//     const cache = await getCacheInstance();
//     const cachedResponse = await cache.match(url);

//     if (cachedResponse) {
//       return cachedResponse.url;
//     }

//     const response = await fetch(url);
//     if (response.ok) {
//       await cache.put(url, response.clone());
//       return response.url;
//     }

//     throw new Error(`Failed to fetch image: ${response.statusText}`);
//   } catch (error) {
//     throw error;
//   }
// };

import { useState, useEffect } from 'react';
import {getImage} from '../utils/parseContent';

// Cache utility functions
let cacheInstance = null;

const getCacheInstance = async () => {
  if (!cacheInstance) {
    cacheInstance = await caches.open('image-cache');
  }
  return cacheInstance;
};

const cacheImage = async (url) => {
  try {
    const cache = await getCacheInstance();
    const cachedResponse = await cache.match(url);

    if (cachedResponse) {
      return cachedResponse.url;
    }

    const response = await fetch(url);
    if (response.ok) {
      await cache.put(url, response.clone());
      return response.url;
    }

    throw new Error(`Failed to fetch image: ${response.statusText}`);
  } catch (error) {
    throw error;
  }
};

// Custom hook for image caching
const useImageCache = (images) => {
  const [cachedImages, setCachedImages] = useState([]);
  const [cachedThumbnails, setCachedThumbnails] = useState([]);
  const [cachedMediumImages, setCachedMediumImages] = useState([]);
  const [cachedSmallImages, setCachedSmallImages] = useState([]);

  useEffect(() => {
    const cacheAllImages = async () => {
      try {
        const cached = await Promise.all(images.map(image => cacheImage(image.url)));
        const cachedThumbs = await Promise.all(images.map(image => {
          const thumbnailUrl = getImage.thumbnail(image.url);
          return thumbnailUrl ? cacheImage(thumbnailUrl) : null;
        }));
        const cachedSmall = await Promise.all(images.map(image => {
          const smallUrl = getImage.small(image.url);
          return smallUrl ? cacheImage(smallUrl) : null;
        }));
        const cachedMedium = await Promise.all(images.map(image => {
          const mediumUrl = getImage.medium(image.url);
          return mediumUrl ? cacheImage(mediumUrl) : null;
        }));

        setCachedImages(cached.filter(url => url !== null));
        setCachedSmallImages(cachedSmall.filter(url => url !== null));
        setCachedThumbnails(cachedThumbs.filter(url => url !== null));
        setCachedMediumImages(cachedMedium.filter(url => url !== null));
      } catch (error) {
        console.error('Error caching images:', error);
      }
    };

    cacheAllImages();
  }, [images]);

  return {
    cachedImages,
    cachedThumbnails,
    cachedMediumImages,
    cachedSmallImages,
  };
};

export default useImageCache;
