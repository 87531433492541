import React, { useState, useEffect } from 'react';
import { Container, Modal, Carousel, Image, Button } from 'react-bootstrap';
import PaginationComponent from "../Portfolio/PaginationComponent";

import DEFAULT_IMAGE from "../../assets/images/NO_IMAGE.webp";
import { getImage } from "../../utils/parseContent";
import useImageCache from '../../utils/cacheUtils';
import CardGallery from './CardGallery';
import GalleryView from './GalleryView';
import CarouselGallery from './CarouselGallery';

import '../Portfolio/Cards.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ImageGallery.css';

const ImageGallery = ({ images, viewType, interval = 5000 }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const { cachedMediumImages, cachedThumbnails } = useImageCache(images);

  const [ITEMS_PER_PAGE, setItemsPerPage] = useState(6);
  const [visibleImagesCount, setVisibleImagesCount] = useState(ITEMS_PER_PAGE);
  const totalPages = Math.ceil(images.length / ITEMS_PER_PAGE);

  const loadMoreImages = () => {
    setVisibleImagesCount(prevCount => Math.min(prevCount + ITEMS_PER_PAGE, images.length));
  };

  const getVisibleThumbnails = () => {
    const thumbnailsToShow = ITEMS_PER_PAGE;
    const half = Math.floor(thumbnailsToShow / 2);
    let start = selectedIndex - half;
    let end = selectedIndex + half;

    if (start < 0) {
      start = 0;
      end = Math.min(thumbnailsToShow, cachedThumbnails.length);
    } else if (end > cachedThumbnails.length) {
      end = cachedThumbnails.length;
      start = Math.max(0, cachedThumbnails.length - thumbnailsToShow);
    }

    return cachedThumbnails.slice(start, end);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setVisibleImagesCount(ITEMS_PER_PAGE);
  };

  const handleSelect = (index) => setSelectedIndex(index);
  const closeModal = () => setActiveModal(null);

  const handleShow = (index, modalType) => {
    setSelectedIndex(index);
    setActiveModal(modalType);
  };

  const openModal = (item) => {
    setModalContent({
      title: item.title || "",
      description: item.description,
      imageUrl: getImage.medium(item.url),
      originalUrl: item.url,
      url: item.button?.link || "#",
      button: item.button || null,
    });
    setActiveModal(viewType);
  };

  const handleImageError = (e) => {
    const originalUrl = e.target.dataset.originalUrl;
    const fallbackAttempted = e.target.dataset.fallbackAttempted;

    if (!fallbackAttempted) {
      e.target.dataset.fallbackAttempted = "true";
      e.target.src = originalUrl;
    } else {
      e.target.src = DEFAULT_IMAGE;
    }
  };

  useEffect(() => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    const formattedItems = images.slice(start, end).map(item => ({
      url: item.url,
      name: item.name,
      description: item.description,
      descriptionLong: item.descriptionLong,
      button: item.button,
      id: item.id,
      title: item.title
    }));
    setCurrentItems(formattedItems);
  }, [currentPage, images, ITEMS_PER_PAGE]);

  useEffect(() => {
    if (viewType === 'card') {
      setVisibleImagesCount(ITEMS_PER_PAGE);
      setCurrentPage(1);
    }
  }, [viewType, ITEMS_PER_PAGE]);

  return (
    <Container>
      {viewType === 'carousel' && (
        <CarouselGallery
          images={images}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          openModal={openModal}
          getVisibleThumbnails={getVisibleThumbnails}
          interval={interval}
        />
      )}
      {viewType === 'gallery' && (
        <>
          <GalleryView
            images={images.slice(0, visibleImagesCount)}
            handleShow={(index) => handleShow(index, 'gallery')}
          />
          {visibleImagesCount < images.length && (
            <div className="text-center mt-4">
              <Button onClick={loadMoreImages}>View More</Button>
            </div>
          )}
        </>
      )}
      {viewType === 'card' && (
        <>
          <PaginationComponent totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          <CardGallery
            currentItems={currentItems}
            openModal={openModal}
            handleImageError={handleImageError}
          />
          <PaginationComponent totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        </>
      )}





      {/* Card modal */}
      {activeModal === 'card' && (
        <Modal centered show onHide={closeModal}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title className="modal-text">{modalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-text">
            <Image
              src={modalContent.imageUrl}
              alt={modalContent.title}
              data-original-url={modalContent.originalUrl}
              onError={handleImageError}
              style={{ width: "100%", height: "auto" }}
            />
            <p className="mt-3 mb-1">{modalContent.description}</p>
          </Modal.Body>

          {modalContent.button && (
            <Modal.Footer>
              {modalContent.button.map((btn, index) => (
                <Button
                  key={index}
                  variant="primary"
                  href={btn.link}
                  target="_blank"
                  onClick={closeModal}
                >
                  {btn.title || "View"}
                </Button>
              ))}
            </Modal.Footer>
          )}
        </Modal>
      )}

      {/* Gallery modal */}
      {activeModal === 'gallery' && (
        <Modal show centered onHide={closeModal} size="lg">
          <Modal.Body>
            <Carousel
              activeIndex={selectedIndex}
              onSelect={handleSelect}
              interval={null}
              className="modal-carousel"
              indicators={false}
            >
              {cachedMediumImages.map((image, index) => (
                <Carousel.Item key={index}>
                  <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
      )}

      {/* Carousel Modal */}
      {activeModal === 'carousel' && (
        <Modal show centered onHide={closeModal} size="lg">
          <Modal.Body>
            <Carousel
              activeIndex={selectedIndex}
              onSelect={handleSelect}
              interval={null}
              indicators={false}
              className="carousel-slider"
            >
              {cachedMediumImages.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>

            <div className="thumbnails mt-3">
              {getVisibleThumbnails().map((image, idx) => {
                return (
                  <img
                    key={idx}
                    src={image}
                    alt={`Thumbnail ${idx + 1}`}
                    className={`thumbnail ${idx === selectedIndex ? 'selected' : ''}`}
                    onClick={() => setSelectedIndex(idx)}  // Update selectedIndex correctly
                  />
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default ImageGallery;