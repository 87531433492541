import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import './Socials.css';

const buttonProps = {
    variant: "link",
    size: "sm",
    className: 'invert-on-hover',
    target: "_blank",
    role: 'button'
};

const SocialMediaIcons = ({ limitIconsOnMobile = false, exclude = [] }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const [socialIcons, setSocialIcons] = useState([]);
    

    async function fetchSocials() {
        try {
            const response = await fetch('https://strapi-production-f4a8.up.railway.app/api/social?populate[SocialMediaSection][populate][SocialMedia][populate]=image&fields[0]=id', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const socials = await response.json();
            setSocialIcons(socials.data.attributes.SocialMediaSection.SocialMedia);
    
        }
        catch (error) {
            console.error('Fetch error(frontend, fetchData):', error);
        }
    }

    useEffect(() => {
        fetchSocials();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const iconsToShow = limitIconsOnMobile && isMobile 
        ? Object.keys(socialIcons).filter(key => 
            ['Discord', 'Linkedin', 'Email'].includes(socialIcons[key].title) &&
            !exclude.includes(socialIcons[key].title.toLowerCase())
          )
        : Object.keys(socialIcons).filter(key => 
            !exclude.includes(socialIcons[key].title.toLowerCase())
          );

    return (
        <Container>
            {iconsToShow.map(iconKey => {
                const icon = socialIcons[iconKey];
                const imageUrl = icon.image.data[0].attributes.url;

                return (
                    <Button
                    key={iconKey}
                    {...buttonProps}
                    href={socialIcons[iconKey].url}
                    >
                        <img src={imageUrl} alt={iconKey} className="social-icons" />
                    </Button>
                );
            })}
        </Container>
    );
};

export default SocialMediaIcons;













//  Using GraphQL



// import React, { useState, useEffect } from 'react';
// import { Container, Button } from 'react-bootstrap';
// import { useQuery, gql } from '@apollo/client';

// import './Socials.css';


// const GET_SOCIALS = gql`
//     query getSocials {
//         social {
//             data {
//             id
//             attributes {
//                 SocialMediaSection {
//                 SocialMedia {
//                     id
//                     title
//                     url
//                     image {
//                     data {
//                         attributes {
//                         name
//                         url
//                         }
//                     }
//                     }
//                 }
//                 }
//             }
//             }
//         }
//     }
// `;

// const buttonProps = {
//     variant: "link",
//     size: "sm",
//     className: 'invert-on-hover',
//     target: "_blank",
//     role: 'button'
// };

// const SocialMediaIcons = ({ limitIconsOnMobile = false }) => {
//     const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
//     const { loading, error, data } = useQuery(GET_SOCIALS);

//     const [socialIcons, setSocialIcons] = useState([]);

//     useEffect(() => {
//         if (data?.social) {
//             setSocialIcons(data.social.data.attributes.SocialMediaSection.SocialMedia);
//         }
//     }, [data]);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth < 500);
//         };

//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error loading social icons</p>;

//     const iconsToShow = limitIconsOnMobile && isMobile 
//         ? socialIcons.filter(icon => ['discord', 'linkedin', 'email'].includes(icon.title.toLowerCase())) 
//         : socialIcons;

//     return (
//         <Container>
//             {iconsToShow.map(icon => {
//                 const imageUrl = icon.image.data[0].attributes.url;

//                 return (
//                     <Button
//                         key={icon.id}
//                         {...buttonProps}
//                         href={icon.url}
//                     >
//                         <img src={imageUrl} alt={icon.title} className="social-icons" loading="lazy"/>
//                     </Button>
//                 );
//             })}
//         </Container>
//     );
// };

// export default SocialMediaIcons;
