import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import './Blog.css';

function timeAgo(date) {
  const now = new Date();
  const seconds = Math.floor((now - new Date(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
}

function Blog() {
  const [posts, setBlogData] = useState([]);
  const recentPosts = posts.slice(0, 3);
  const otherPosts = posts.slice(3);
  // const [isLoading, setIsLoading] = useState(true);

  const fetchBlogData = async () => {
    try {
    const response = await fetch('https://strapi-production-f4a8.up.railway.app/api/blogs?populate=image,categories,createdBy&sort=createdAt:desc', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blogs = await response.json();
      console.log("Blog data...", blogs);
      setBlogData(blogs.data);
    } catch (err) {
      console.error('Fetch error:', err);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }


  return (
    <Container className="my-5">
      <Row>
        {recentPosts.map((post, index) => (
          <Col xs={12} md={index === 0 ? 12 : 6} key={post.id} className="mb-4">
            <Link to={`/blog/${post.attributes.slug}`} className="text-decoration-none text-reset">
              <Card className="h-100 position-relative">
                <Card.Img
                  variant="top"
                  src={post.attributes.image?.data?.attributes?.url || '../images/default_image.jpg'}
                  alt={post.attributes.title}
                  style={{ height: index === 0 ? '300px' : '175px', objectFit: 'cover' }}
                />
                <Card.ImgOverlay className="d-flex flex-column justify-content-end text-start gradient-overlay">
                <Card.Title className="text-white mb-0 fw-bold">{post.attributes.title}</Card.Title>
                  <Card.Text className="text-white fw-bold">{post.attributes.description || '\u00A0'}
                  <p>
                    <small className = 'text-grey'>Author: {post.attributes.createdBy.data.attributes.firstname}  • {timeAgo(post.attributes.publishedAt)}</small>
                    {/* <small className = 'text-grey'> {timeAgo(post.attributes.publishedAt)}</small> */}
                  </p>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    <Row>
      {otherPosts.map((post, index) => (
        <Col xs={12} key={index} className="mb-4">
          <Link to={`/blog/${post.attributes.slug}`} className="text-decoration-none text-reset">
            <Card className="bg-transparent border-0">
              <Row noGutters>
                <Col xs={4} md={3}>
                  <Card.Img
                  src={post.attributes.image?.data?.attributes?.url || '../images/default_image.jpg'}
                  alt={post.attributes.title}
                    style={{ height: '150px', objectFit: 'cover' }}
                  />
                </Col>
                <Col xs={8} md={9}>
                  <Card.Body className='text-start'>
                    <Card.Title className="font-weight-bold mt-4 mb-0">{post.attributes.title}</Card.Title>
                    <Card.Text className="">{post.attributes.description}
                      <p className="mt-1">
                      <small className='text-muted'>
                      {post.attributes.createdBy.data.attributes.firstname} • {timeAgo(post.attributes.publishedAt)}
                      </small>
                      </p>
                    </Card.Text>
                    {/* <Card.Text className="text-muted small">
                      {post.author} • {timeAgo(post.attributes.publishedAt)}
                    </Card.Text> */}
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
    </Container>
  );
}

export default Blog;


















// BLOG STYLE #1

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Container, Row, Col, Card, Button } from 'react-bootstrap';

// function Blog() {
//   const [posts, setBlogData] = useState([]);
//   // const [isLoading, setIsLoading] = useState(true);

//   const fetchBlogData = async () => {
//     try {
//       const response = await fetch('https://strapi-production-f4a8.up.railway.app/api/blogs?populate=image,%20categories', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const blogs = await response.json();
//       // console.log("Blog data...", blogs);
//       setBlogData(blogs.data);
//     } catch (err) {
//       console.error('Fetch error:', err);
//     } finally {
//       // setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchBlogData();
//   }, []);

//   // if (isLoading) {
//   //   return <div>Loading...</div>;
//   // }

//   return (
//     <Container className="my-5">
      // <Row>
      //   {posts.map(post => (
      //     <Col xs={12} sm={12} md={12} lg={12} key={post.id} className="mb-4">
      //       <Card style={{ height: '100%' }}>
      //         <Card.Img  src={post.attributes.image?.data?.attributes.url} 
      //           style={{ height: '200px', objectFit: 'cover', objectPosition: 'middle' }} 

      //         />
      //         <Card.Body>
      //           <Card.Title>{post.attributes.title}</Card.Title>
      //           <Card.Text>{post.attributes.description}</Card.Text>
      //           <Link to={`/blog/${post.attributes.slug}`}>
      //             <Button variant="primary" className="mt-4">Read More</Button>
      //           </Link>
      //         </Card.Body>
      //       </Card>
      //     </Col>
      //   ))}
      // </Row>
//     </Container>
//   );
// }

// export default Blog;