import React from "react";
import { Spinner } from "react-bootstrap";

import Portfolio from "../components/Portfolio/Portfolio";
import HeroSection from "../components/Hero/Hero";
import Services from "../components/Services/Services";


import "../style/App.css";



function Home({props}) {
    
      if (props.isLoading) {
        return (
          <div className="bg-black vh-100 vw-100 d-flex justify-content-center align-items-center slide-in">
            <div className="text-center">
              <Spinner animation="border" variant="light" role="status" className="loading-spinner" />
              <p className="text-light mt-3">Loading...</p>
            </div>
          </div>
        );
      }
    return (
    <div className="App">

        <div id="home">
          <HeroSection heroProps={props.heroData}/>
        </div>
        <div id="services">
          <Services servicesProps = {props.servicesData}/>
        </div>

        <div id="portfolio" className="portfolio">
          <Portfolio activeSection={props.activeSection} setActiveSection={props.setActiveSection} />
        </div>


      </div>

    )
}

export default Home