import React from 'react';

import SocialMediaIcons from '../Socials/Socials';
import './Footer.css';


const Footer = () => {
  return (

    


    <footer className='text-center bg-dark pt-3 pb-3 text-white'>

      
      {/* <SocialMediaIcons /> */}

      <div className='text-center  p-3'>
      © 2024 GitagoDesigns. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;