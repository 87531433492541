import React, { useState, useRef, useContext} from 'react';
import { NavLink } from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import SocialMediaIcons from '../Socials/Socials';
import './Navbar.css';

const NavbarSection = ({ activeSection, setActiveSection }) => {
  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  // const handleNavigation = (section) => {
  //   toggleOverlay();
 
  //   setActiveSection(section);

  // };

  // const toggleOverlay = () => {
  //   setShow(!show);
  // };
  
  return (
    <div className="px-3 navbarTop d-flex justify-content-between align-items-center">
    <div>David "Gitago" Bell</div>
    <div id= "socialmedia-top" className="d-flex align-items-center">
    <SocialMediaIcons limitIconsOnMobile={true} />
    </div>
  </div>
    // <div className={`px-4 navbarTop ${show ? 'overlay-open' : ''}`}>
    //   David "Gitago" Bell
     
    //   <div className="hamburger-menu" ref={target} onClick={toggleOverlay}>
      
    //     <FontAwesomeIcon icon={faBars} />
    //   </div>
    //   <Overlay target={target.current} show={show} placement="bottom">
    //     {({ placement, arrowProps, show: _show, popper, ...props }) => (
    //       <div
    //         {...props}
    //         style={{
    //           position: 'fixed',
    //           top: 0,
    //           left: 0,
    //           height: '100vh',
    //           width: '100vw',
    //           backgroundColor: 'rgba(0, 0, 0, 0.95)',
    //           zIndex: 1000,
    //           ...props.style,
    //         }}
    //       >
    //         <div className="overlay-title px-4 mt-3">David "Gitago" Bell</div>
    //         <div className="overlay-content">
             
              
    //           <button className="close-btn" onClick={toggleOverlay}>
    //             <FontAwesomeIcon icon={faXmark} />
    //             </button>
    //           <NavLink href="#home" active={activeSection === 'home'} onClick={() => handleNavigation('home')}>Home</NavLink>
    //           <NavLink href="#production" active={activeSection === 'production'} onClick={() => handleNavigation('production')}>Production</NavLink>
    //           <NavLink href="#developing" active={activeSection === 'developing'} onClick={() => handleNavigation('developing')}>Developing</NavLink>
    //           <NavLink href="#graphicDesign" active={activeSection === 'graphicDesign'} onClick={() => handleNavigation('graphicDesign')}>Graphic Design</NavLink>
              
            
    //           <SocialMediaIcons />
         
    //         </div>
    //       </div>
          
    //     )}
    //   </Overlay>
    // </div>
  );
};

export default NavbarSection;