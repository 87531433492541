import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const loadIcon = (iconName) => {
  const iconLookup = { prefix: 'fa-solid', iconName: iconName };
  const iconDefinition = findIconDefinition(iconLookup);
  if (iconDefinition) {
    return iconDefinition;
  } else {
    console.warn(`Icon "${iconName}" not found in FontAwesome library.`);
    return findIconDefinition({ prefix: 'fas', iconName: 'question-circle' }); // Default icon if not found
  }
};

export default loadIcon;