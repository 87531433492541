import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css';
// import aboutImage from '../../assets/images/about.jpg';

import {TextWithLineBreaks} from '../../utils/parseContent';
// import { useQuery, gql } from '@apollo/client';

// const heroProps = {
//   title: "Driven by Passion\nCommitted to Excellence.",
//   text: "Hello there! My name is David Bell\nI enjoy creating things that make other peoples life easier while constantly evolving my skillset.",
//   buttons: [
//     { text: "VIEW PROJECTS", variant: "outline-primary", link: "#portfolio" },
//     { text: "VIEW RESUME", variant: "outline-secondary", link: "https://www.linkedin.com/in/david-bell-68277256/" }
//   ]
// };

// const GET_PORTFOLIO_BY_TITLE = gql`
//   query getPortfolioByTitle($title: String!) {
//     portfolios {
//       data {
//         id
//         attributes {
//           name
//           createdAt
//           button(filters: { title: { eq: $title } }) {
//             id
//          #  title
//          #  link
//          #  isExternal
//          #  type
//             Images {
//               data {
//                 id
//                 attributes {
//                   name
//                   alternativeText
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;


const HeroSection = ( {heroProps} ) => {

  //// WORKING QUERY THINGY
  // const [title, setTitle] = useState("Production");

  // const { loading, error, data } = useQuery(GET_PORTFOLIO_BY_TITLE, {
  //   variables: { title }
  // });

  // useEffect(() => {
  //   if (data && data.portfolios) {
  //     const images = data.portfolios.data.flatMap(portfolio =>
  //       portfolio.attributes.button.flatMap(button =>
  //         button.Images.data.map(image => image.attributes)
  //       )
  //     );
  //     // console.log("Images:", images);
  //   }
  // }, [data]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  // // Ensure data and data.portfolios are defined before accessing their properties
  // if (!data || !data.portfolios) return <div>No data available</div>;

  // // Extract images from the response
  // const images = data.portfolios.data.flatMap(portfolio =>
  //   portfolio.attributes.button.flatMap(button =>
  //     button.Images.data.map(image => image.attributes)
  //   )
  // );

  // console.log("Images:", images);




  // useEffect(() => {
  //   console.log("HeroData updated (image):", heroProps.heroProps.image.data.attributes);
  // }, [heroProps]);

  // Assuring that the link is an array no matter the length
  const links = Array.isArray(heroProps.link) ? heroProps.link : [heroProps.link];

  
  return (
    <section className="hero-div" id="home">
      <div className="hero-content-wrapper">
        <div className="hero-content">
          <h2 className="hero-title mb-3">
            <TextWithLineBreaks text={heroProps.title} />
            </h2>
          <hr style={{ width: '75%' }} />
          <div className="hero-text">
            <p className="mt-3">
              <TextWithLineBreaks text={heroProps.description} />
            </p>
            <div className="hero-buttons mt-4">
            {links.map((link, index) => (
                <Button
                  key={index}
                  variant={`outline-${link.type}`}
                  href={link.link}
                  target={link.isExternal ? "_blank" : "_self"}
                  className={`custom-outline-${link.type.toLowerCase()}`}
                >
                  {link.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="hero-image">
          <div className="image-wrapper">
            {/* <Image src={aboutImage} alt="About" fluid /> */}
            <Image src={heroProps.image.data.attributes.url} alt="About" fluid />

            <div className="blue-box">
              <p className="blue-box-text">
                <TextWithLineBreaks text={heroProps.imageQuote} />
                </p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default HeroSection;