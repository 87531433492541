import React from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';

import DEFAULT_IMAGE from "../../assets/images/NO_IMAGE.webp";
import useImageCache from '../../utils/cacheUtils';
import { getImage } from "../../utils/parseContent";

const CarouselGallery = ({ images, interval, openModal, getVisibleThumbnails, selectedIndex, setSelectedIndex }) => {
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const { cachedThumbnails } = useImageCache(images);


  return (
    <>
      {/* Main Carousel */}
      <Row>
        <Col>
          <Carousel
            activeIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
            interval={interval}
            variant='dark'
            indicators={false}
            keyboard
            wrap
          >
            {images.map((image, idx) => (
              <Carousel.Item key={idx}>
                <img
                  src={getImage.small(image.url) || DEFAULT_IMAGE}
                  alt={`Image ${idx + 1}`}
                  className="carousel-logos"
                  onClick={() => openModal(image)} // Use openModal instead of internal handleShow
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>

      {/* Thumbnails */}
      <Row className="mb-5">
        <Col>
          <div className="thumbnails">
            {getVisibleThumbnails().map((image, idx) => {
              const actualIndex = cachedThumbnails.indexOf(image);
              return (
                <img
                  key={idx}
                  src={image}
                  alt={`Thumbnail ${actualIndex + 1}`}
                  className={`thumbnail ${actualIndex === selectedIndex ? 'selected' : ''}`}
                  onClick={() => setSelectedIndex(actualIndex)}  // Correctly set the selectedIndex
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CarouselGallery;
